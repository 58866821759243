export function openBlankWindow(title, hashURL, htmlContent) {
  var win = window.open('', '_blank');
  win.location.hash = hashURL;
  win.document.title = title;
  win.document.body.innerHTML = htmlContent;
}

export const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    const groupKey = x.data[key];
    (rv[groupKey] = rv[groupKey] || []).push(x);
    return rv;
  }, {});
}

export function defaultDateFormat(date) {
  let datetime = Date.now();
  if (!isNaN(Date.parse(date))) datetime = new Date(date);

  const format = new Date(datetime - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);

  return format;
}

export function createDateTimeFromString(dateString, time24String) {
  // Split date and time strings
  const dateComponents = dateString.split('-');
  const timeComponents = time24String.split(':');

  // Extract year, month, day, hour, and minute
  const year = parseInt(dateComponents[0], 10);
  const month = parseInt(dateComponents[1], 10) - 1; // Months are zero-based in JavaScript
  const day = parseInt(dateComponents[2], 10);
  const hour = parseInt(timeComponents[0], 10);
  const minute = parseInt(timeComponents[1], 10);

  // Create a new Date object
  const dateTime = new Date(year, month, day, hour, minute);

  return dateTime;
}

export function toValidDateTime(dateTime) {
  const year = dateTime.getFullYear();
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); 
  const day = dateTime.getDate().toString().padStart(2, '0');
  const hour = dateTime.getHours().toString().padStart(2, '0');
  const minute = dateTime.getMinutes().toString().padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:00`;

  return formattedDateTime;
}

export function makeid(length) {
  let result = '';
  const specialChar = '!$@-_';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  characters += 'abcdefghijklmnopqrstuvwxyz';
  characters += '0123456789';
  characters += specialChar;
  result = generateRandom(characters, 40);
  result = generateRandom(result + characters, length);
  return modifyEndStartWith(result, specialChar);
}

function generateRandom(characters, length) {
  var result = '';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// if start || end with specialChars replace it
function modifyEndStartWith(result, specialChars) {
  var startWith = result[0];
  const lastIdx = result.length - 1;
  var endWith = result[lastIdx];

  let outResult = result;
  if (specialChars.indexOf(startWith) != -1) {
    outResult = outResult.replace(startWith, 'a');
  }
  if (specialChars.indexOf(endWith) != -1) {
    outResult = outResult.substring(0, lastIdx) + 'z';
  }

  return outResult;
}
